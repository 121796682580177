.hero-section {
    padding: 28%;
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url('./../../public/images/teampic.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hero-heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFF;
    text-align: center;
    width: 100%;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
  }
  .hero-heading-text{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 300;
    line-height: 54px;
  }
  
  .carousel-container {
position: relative;
overflow: hidden;
width: 100%;
max-width: 800px;
margin: auto;
}

.carousel {
display: flex;
transition: transform 0.5s ease-in-out;
}

.carousel-image {
min-width: 100%;
max-width: 100%;
}

/* Centered Navigation Buttons */
.carousel-controls {
position: absolute;
bottom: 60px;
left: 50%;
transform: translateX(-50%);
display: flex;
gap: 30px; /* Adjust space between buttons */
}

/* Individual Button Styling */
.carousel-control-prev,
.carousel-control-next {
margin-left: 20px;
font-size: 2rem;
cursor: pointer;
padding: 10px;
border-radius: 50%;
height: 50px;
width: 50px;
display: flex;
align-items: center;
justify-content: center;
background: #F7F7FD;
color: #333 !important;
border: none;
opacity: 1;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
.carousel-controls {
  gap: 20px;
}

.carousel-control-prev,
.carousel-control-next {
  height: 40px;
  width: 40px;
  font-size: 1.5rem;
}
}
