
body {
    font-family: 'Inter', 'Poppins', Arial, sans-serif;
    scroll-behavior: smooth;
    font-style: normal;
    font-weight: 300; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  [data-aos] {
    opacity: 0; /* Elements hidden by default */
    transition-property: transform, opacity;
  }
  
  [data-aos].aos-animate {
    opacity: 1;
  }
/* Basic styling for each section */
  /* Navbar Styling */
  .navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
  }
  a.navbar-brand {
    color: #18181B !important;
    padding: 0;
    font-weight: 300;
    line-height: 23.80px;
    word-wrap: break-word;
    font-family: Inter;
    font-size: 14px !important;
    letter-spacing: -0.18px;
  }
  .dropdown-item {
    display: flex;
    align-items: center; /* Align icon and text vertically */
    color: #18181B !important;
    font-weight: 300;
    font-family: Inter, sans-serif;
    font-size: 14px !important;
    padding: 10px 1.5rem; /* Adjust padding for better spacing */
    white-space: nowrap;
}

.dropdown-item svg, 
.dropdown-item i {
    width: 18px; /* Ensures uniform icon size */
    height: 18px;
    margin-right: 8px; /* Adjust spacing between icon and text */
    flex-shrink: 0; /* Prevents icon from shrinking */
}

.dropdown-menu {
    min-width: 220px; /* Prevents overlapping text */
}
  .contact-header-btn{
    border-radius: 99px;
    font-size: small;
    display: flex;
    padding: 4px 8px 4px 8px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: small;
    font-style: normal;
    font-weight: 500;
    line-height: 23.8px;
    letter-spacing: -0.09px;
    padding: 4px 20px;
  }
  footer{
    padding: 5%;
    background-color: #ffffff;
  }
  .footer-divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
    position: relative;
  }

  .footer-divider::before {
    content: "";
    width: 60%;
    height: 1px;
    background-color: #ccc;
  }

  .footer-divider i {
    margin: 0 10px;
    color: #555;
  }

  .footer-divider::after {
    content: "";
    width: 40%;
    height: 1px;
    background-color: #ccc;
  }
  .footer-links a, .footer-font-size-300 {
    opacity: 0.50;
    color: #0A142F;
    font-size: 16px;
    font-weight: 300;
    word-wrap: break-word;
    color: var(--Dark, #0A142F);
    font-size: 14px;
    font-style: normal;
    line-height: normal;  }

  .footer-links a:hover {
    text-decoration: underline;
  }

  footer {
    position: relative;
    background-color: #f8f9fa;
  }

  .footer-image {
    position: absolute;
    bottom: 70%;
    right: 10px;
    width: 120px; /* Adjust the size as needed */
    height: auto;
    z-index: 1;
    opacity: 0.8; /* Optional transparency */
  }
  .career-overlay-image {
    position: relative;
    top: 36px;
    left: -9%;
    width: 20px;
    height: auto;
    z-index: 1;
    opacity: 0.8;
  }

  .offering-image{
    position: absolute;
    bottom: 70%;
    left: 10px;
    width: 120px;
    height: auto;
    z-index: 1;
    opacity: 0.8;
  }
  .slider-image{
    position: absolute;
    top: 0%;
    right: 25%;
    width: 120px;
    height: auto;
    z-index: 1;
    opacity: 0.8;
  }
  .scalable-solution-overlay {
    position: absolute;
    bottom: 0%;
    left: 30%;
    width: 80px;
    height: auto;
    z-index: 111;
    opacity: 0.8;
  }

  /* General Styles */
.decorative-image-style {
  position: absolute;
  top: 2%;
  right: 10%;
  width: 60px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}
.feedback-image-style{
  position: absolute;
  top: 5%;
  left: 5%;
  height: 150px;
  width: 70%; /* Adjust the size as needed */
  height: auto;
  z-index: -999999;
  opacity: 0.8; /* Optional transparency */
}

.decorative-image-style-2 {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 60px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}

.about-border-image {
  position: relative;
  top: 33px;
  left: -1%;
  width: 48px;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}

.industry-border-image {
  position: relative;
  top: 40px;
  left: -48px;
  width: 40px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}

.story-border-image {
  position: absolute;
  top: 22%;
  left: 5%;
  width: 30px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}

.story-border-image2 {
  position: relative;
  top: 14px;
  left: -20px;
  width: 48px;
  height: auto;
  z-index: 1;
  opacity: 0.8;
}
.story-border-image4{
  position: relative;
  top: -40px;
  left: -36px;
  width: 30px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}
.story-border-image5{
  position: relative;
  top: 35px;
  left: -36px;
  width: 30px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}
.offer-bg-image{
  position: absolute;
  bottom: 60%;
  left: -10%;
  width: 60px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}
.industry-bg-image{
  position: absolute;
  bottom: 35%;
  left: -10%;
  width: 60px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}

.staff-aug-bg-image{
  position: absolute;
  bottom: 56%;
  left: -8%;
  width: 60px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}
.creative-diff-bg-image {
  position: absolute;
  bottom: 90%;
  right: -20%;
  width: 60px; /* Adjust the size as needed */
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}
.staff-diff-bg-image{
  position: absolute;
  bottom: 90%;
  right: -20%;
  width: 70px;
  height: auto;
  z-index: 1;
  opacity: 0.8; /* Optional transparency */
}
/* Responsive Styles */
@media (max-width: 1200px) {
  .decorative-image-style {
    width: 50px;
    bottom: 5%;
    right: 8%;
  }
}

@media (max-width: 992px) {
  .decorative-image-style {
    width: 45px;
    bottom: 8%;
    right: 5%;
  }

  .story-border-image {
    top: 130%;
  }

}

@media (max-width: 768px) {
  .decorative-image-style {
    width: 40px;
    bottom: 10%;
    right: 5%;
  }

  .story-border-image {
    top: 135%;
    width: 20px;
  }

}

@media (max-width: 576px) {
  .decorative-image-style {
    width: 35px;
    bottom: 12%;
    right: 5%;
  }


  .story-border-image {
    top: 140%;
    width: 15px;
  }

}


  

  .footer-content {
    position: relative;
    z-index: 2;
  }
  
  .location-image-section {
    padding: 5%;
    padding-bottom: 0 !important;
    text-align: center;
  }

  .location-image-section img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    .location-image-section {
      padding: 60px 40px;
    }
  }


  .partner-section {
    background-color: #f8f9fa;
    padding: 40px 20px;
    text-align: center;
  }

  .carousel-height {
    max-height: 750px;
  }

  .carousel-inner {
    display: flex;
    align-items: center;
  }

  .carousel-item {
    display: flex;
    justify-content: center;
  }

  .carousel-item img {
    max-height: 100px;
    margin: 0 15px;
  }

  .join-us-btn {
    margin-top: 20px;
    font-size: 16px;
    padding: 10px 20px;
  }
  .image-partner{
    filter: invert(1) brightness(0);
    height: 40px;
  }

 

.our-team h1, .our-team h5 {
    color: #333; /* Darker text for contrast against the background */
}

.our-team .card {
    background: #fff; /* Ensure cards have a white background */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
    border-radius: 10px; /* Rounded corners for the cards */
}

.our-team .card-img-top {
    border-radius: 50%; /* Makes the image circular */
    width: 80px; /* Restricts the size of the image */
    height: 80px; /* Ensures the image remains a circle */
    object-fit: cover; /* Ensures image fits perfectly inside the circle */
}

.our-team ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.our-team ul li a {
    color: #333; /* Dark icon color */
    font-size: 16px;
    transition: color 0.3s;
}

.our-team ul li a:hover {
    color: #FFDD00; /* Highlight color on hover */
}

.our-team ul li {
    display: flex;
    align-items: center;
}

.our-team h2 {
    color: #000; /* Make the heading stand out */
    font-weight: 700;
    margin-bottom: 30px;
}
.about-us-section, .our-story-section {
  border-left: 4px solid !important;
  border-image: linear-gradient(to bottom, #6CC6F3, #97C686) 1; /* Apply the gradient to the left border */  padding-left: 20px; /* Padding to separate the text from the border */
}

.about-us-section h5, .our-story-section h5 {
  font-weight: bold;
  letter-spacing: 1px;
}

.about-us-section h4, .our-story-section h4 {
  font-size: 1.3rem;
  line-height: 1.6;
}

.auto-margin {
  margin-left: auto;
}

.industry-about-us-section, .our-story-section {
  border-left: 0px solid;
  border-image: linear-gradient(to bottom, #6CC6F3, #97C686) 1; /* Apply the gradient to the left border */  padding-left: 20px; /* Padding to separate the text from the border */
}

.industry-about-us-section h5, .our-story-section h5 {
  font-weight: bold;
  letter-spacing: 1px;
}

.industry-about-us-section h4, .our-story-section h4 {
  font-size: 1.3rem;
  line-height: 1.6;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.row.align-items-center {
  margin-top: 20px;
}

.border-left {
  border-image: linear-gradient(270deg, #6CC6F3 0%, #97C686 100%); /* Apply gradient to border */
  padding-left: 20px;
}

.text-uppercase {
  text-transform: uppercase;
}

/* Adjustments for images and text alignment */
.col-md-6 {
  margin-bottom: 30px;
}

.row.align-items-center img {
  margin-right: 20px;
}
.carousel-item img {
  margin-right: 40px !important;
}
.radius{
  border-radius: 99px; /* Makes the image circular */
}
.blue-bg{
  color: #fff;
  background: linear-gradient(0deg, #3171DE 0%, #4AC0F2 100%);
}
.green-bg{
  color: #fff;
background: linear-gradient(232.88deg, #62AE6E 28.46%, #EDE14F 162.02%);
}
.navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
.dropdown-menu, .dropdown-item{
  border: 0px !important;
  font-weight: 400 !important;
}
.mployr-feedback {
  position: relative;
  overflow: hidden;
  width: 1256px;
  height: 680px;
  padding: 38px 66px !important;
  border-radius: 35.386px;
  background: rgba(24, 24, 27, 0.06);
}
.mployr-feedback::before {
  content: '';
  position: absolute;
  width: 140%;
  height: 140%;
  filter: blur(60px);
  z-index: -1;
  position: absolute;


opacity: 0.32;
background: linear-gradient(270deg, #FD0 0%, #46F883 100%);
filter: blur(50px);
border-radius: 35.386px;
}
.button-feedback{
  position: absolute;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  bottom: 2%;
  left: 50%;
  transform: translate(-25%);
}

.feedback-image {
  width: 50px;
  height: 40px;
  background-size: cover;
}
.feedback-by {
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: cover;
  left: 8%;
}
.card-career{
  background: linear-gradient(229deg, #3171DE 0%, #4AC0F2 100%);
  border-radius: 34px;
  border: 0px;
}
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0px;
  box-shadow: 0px 8px 32px rgba(24, 24, 27, 0.06), 0px 0px 0px 1px rgba(24, 24, 27, 0.04);
  border-radius: 12px;
}

.col-6 {
  display: flex;
  align-items: stretch;
}

.card .p-3 {
  flex-grow: 1;
}

img {
  max-width: 100%;
  height: auto;
}

.card .btn {
  margin-top: auto;
}
.our-team {
  position: relative;
  overflow: hidden;
}
.our-team::before {
  content: '';
  position: absolute;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  opacity: 1;
  filter: blur(60px);
  z-index: -1;
  position: absolute;
width: 988px;
height: 432px;
left: calc(50% - 988px/2);
top: calc(50% - 432px/2 + 101.48px);

background: linear-gradient(90deg, #FFDD00 0%, #46F883 100%);
opacity: 0.2;
filter: blur(99px);
}
.slider-sec {
  position: relative;
  overflow: hidden;
}
.slider-sec::before {
  content: '';
  position: absolute;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  filter: blur(60px);
  z-index: -1;
  position: absolute;
width: 100%;
height: 200px;
left: calc(50% - 40%/2);
top: calc(50% - 20%/2 + 10%);

background: linear-gradient(88deg, #FFDD00 0%, #46F883 90%);
opacity: 0.4;
filter: blur(50%);
}

h1{
  font-style: normal;
  font-weight: 300;
  padding: 0;
  font-size: 72;
  font-weight: 300;
  word-wrap: break-word;
}
.sliderSubHeading{
  font-style: normal;
  font-weight: 300;
  font-weight: 300;
  word-wrap: break-word;
  text-transform: uppercase;
  letter-spacing: 1;
  color: #6A6A77;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.8px; /* 170% */
  letter-spacing: 1px;
  text-transform: uppercase;
}
.yourPartner {
  color: #F3BC4C !important;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  word-wrap: break-word;
}
h6{
  color: #18181B !important;
  font-size: 16px;
  font-family: Inter;
  font-weight: 300;
  line-height: 32px;
  word-wrap: break-word;
}
.font-weight-500{
  font-weight: 500;
}
.keyIndustryCard{
  background: white !important;
  box-shadow: 0px 5.661764621734619px 14.154411315917969px rgba(0, 0, 0, 0.13) !important;
  border-radius: 35.39px !important;
}
.deliveringCard{
  box-shadow: 0px 5.661764621734619px 14.154411315917969px rgba(0, 0, 0, 0.13) !important;
  border-radius: 35.39px !important;
}
.media-bg {
  position: relative;
  overflow: hidden;
}
.media-bg::before {
  content: '';
  position: absolute;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  opacity: 1;
  filter: blur(60px);
  z-index: -1;
  position: absolute;
width: 988px;
height: 432px;
left: calc(50% - 988px/2);
top: calc(50% - 432px/2 + 101.48px);

background: linear-gradient(90deg, #FFDD00 0%, #46F883 100%);
opacity: 0.5;
filter: blur(99px);
}

/* Top Bar */
.top-bar {
  background-color: #000; /* Black background */
  color: #fff; /* White text for icons */
  font-size: 16px; /* Icon size */
}

/* Icon Styles */
.top-bar a {
  color: #fff; /* Icon color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease;
}

.top-bar a:hover {
  color: #ccc; /* Light gray on hover */
}
.social-icon {
  height: 18px;
  width: 18px;
  object-fit: contain; /* Ensures the image scales properly */
}
.blogHeading{
  color: #18181B; font-size: 23.44px; font-family: Inter; font-weight: 300; line-height: 28.80px; word-wrap: break-word;
}
.blogSubHead{
  color: #4D4D56; font-size: 14px; font-family: Inter; font-weight: 300; line-height: 23.80px; word-wrap: break-word;
}
.blogCard{
  background: white;
  border: 0px;
  box-shadow: 0px 0px 0px 1px rgba(24, 24, 27, 0.04); 
  border-radius: 12px !important;
}
.learnMore{
  color: #18181B; font-size: 14px; font-family: Inter; font-weight: 500; line-height: 23.80px; word-wrap: break-word
}
.staff-card{
  position: relative;
  background: white!important;
  box-shadow: 0px 5.661764621734619px 14.154411315917969px rgba(0, 0, 0, 0.13)!important;
  border-radius: 35.39px !important;
  overflow: hidden!important;
}
.staff-card-2{
  position: relative;
  box-shadow: 0px 5.661764621734619px 14.154411315917969px rgba(0, 0, 0, 0.13)!important;
  border-radius: 35.39px !important;
  overflow: hidden!important;
}
.staff-card-small{
  color: #525252; font-size: 14px; font-weight: 300; line-height: 24px; word-wrap: break-word;
}
.staff-card-head{
  color: #3D3D3D; 
  font-size: 1.5rem; font-weight: 500; line-height: 40px; word-wrap: break-word;
}
.staff-card-head-2{
  font-size: 1.5rem; font-weight: 500; line-height: 40px; word-wrap: break-word;
}
.staff-card-small-2{
  font-size: 14px; font-weight: 300; line-height: 24px; word-wrap: break-word;
}
.approach-bullet{
  color: #9E9EA9; font-size: 22px; font-family: Inter; font-weight: 500; line-height: 23.80px; word-wrap: break-word;
}
p{
  font-weight: 300; line-height: 24px; word-wrap: break-word;
}
.matter-head{
  font-size: 36px; font-family: Inter; font-weight: 500; line-height: 54px; word-wrap: break-word;
}
.matter-card-head{
  height: 28.80px; color: white; font-size: 23.44px; font-family: Inter; font-weight: 300; line-height: 28.80px; word-wrap: break-word;
}
.matter-card-body{
  color: white; font-size: 14px; font-family: Inter; font-weight: 300; line-height: 23.80px; word-wrap: break-word;
}
.join-expert-card{
  background: white; box-shadow: 0px 0px 0px 1px rgba(24, 24, 27, 0.04); border-radius: 16px; overflow: hidden;
}
.join-card{
  border: 0!important;
  background: white !important;
  box-shadow: 0px 0px 0px 1px rgba(24, 24, 27, 0.04)!important;
  border-radius: 12px!important;
  overflow: hidden!important;
}
.our-team-p{
  color: #18181B; font-size: 16px; font-family: Inter; font-weight: 300; line-height: 32px; word-wrap: break-word
}
#contactForm input, #contactForm textarea {
  background: white;
  border-radius: 6px;
  overflow: hidden;
  border: 0px !important;
}
.sass-card{
  border-radius: 35.386px!important;
  background: #FFF!important;
  box-shadow: 0px 5.662px 14.154px 0px rgba(0, 0, 0, 0.13)!important;
}
.about-us-head{
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2px;
  background: linear-gradient(0deg, #F3BC4C 0%, #F3BC4C 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: 4%;
}
.about-us-subHead{
  color: var(--black-70, #3D3D3D);
  font-size: 20px;
  font-weight: 400 !important;
  padding-left: 5%;
  font-style: normal;
  font-weight: 350;
  line-height: 38px;
  width: 750px;
  margin-bottom: 80px;
}
.about-us-subHead2, .about-us-subHead2-story {
  padding: 0% 7%;
  color: #3D3D3D;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 26px;
}
.about-us-subHead2-story{
  padding: 0% 5% !important;
}
.cyber-image{
  width: 216px;
height: 120px;
flex-shrink: 0;
  background: url('./../public/images/Cyber-Essentials-Logo-1 1.png') #fff 50% / cover no-repeat;
}
.cyber-p{
  color: #3D3D3D;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.about-card{
  border-radius: 35.386px!important;
  background: #FFF !important;
  box-shadow: 0px 4px 14.154px 0px rgba(0, 0, 0, 0.13)!important;
}
.about-card-head-part{
  color: var(--black-60, #525252);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
.about-card-head{
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  background: var(--Gradient2, linear-gradient(180deg, #3171DE 0%, #704AF2 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-card-head-body{
  color: var(--black-60, #525252);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}
.company-etho-head-part{
  color: #18181B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.etho-card{
  border-radius: 12px !important;
    background: #FFF!important;
    box-shadow: 0px 8px 32px 0px rgba(24, 24, 27, 0.06), 0px 0px 0px 1px rgba(24, 24, 27, 0.04)!important;
}
.etho-card-head{
  color: #18181B;
font-family: Inter;
font-size: 21px !important;
font-style: normal;
font-weight: 300;
line-height: 38.4px; /* 123.871% */
letter-spacing: -0.69px;
}
.etho-card-body{
  color: #18181B;
font-family: Inter;
font-size: 16px !important;
font-style: normal;
font-weight: 300;
line-height: 32px; /* 200% */
letter-spacing: -0.18px;
}
.etho-right-card-head{
color: #18181B;
font-family: Inter;
font-size: 23.625px;
font-style: normal;
font-weight: 300;
line-height: 28.8px; /* 121.905% */
letter-spacing: -0.47px;
}
.etho-right-card-body{
  color: #4D4D56;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 23.8px; /* 170% */
letter-spacing: -0.09px;
}
.our-team-head-part{
  color: #6A6A77;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px; /* 170% */
  letter-spacing: 1px;
  text-transform: uppercase;
}
.etho-card{
  border-radius: 16px !important;
background: #FFF!important;
box-shadow: 0px 8px 32px 0px rgba(24, 24, 27, 0.06), 0px 0px 0px 1px rgba(24, 24, 27, 0.04)!important;
}
.etho-card-head{
  color: #18181B;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: 21.6px; /* 120% */
letter-spacing: -0.26px;
}
.etho-card-head-part{
  color: #4D4D56;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 23.8px; /* 170% */
letter-spacing: -0.09px;
}
.our-story-border{
  width: 0px;
height: 480px;
transform: rotate(0.063deg);
flex-shrink: 0;
stroke-width: 4px;
stroke: #6CC6F3;
}
.slider-icon-div{
  color: #18181B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 23.8px; /* 170% */
  letter-spacing: -0.09px;
}
.rated-head{
  color: #18181B;
text-align: center;
font-family: Inter;
font-size: 56px;
font-style: normal;
font-weight: 300;
line-height: 67.2px; /* 120% */
letter-spacing: -1.25px;
}
.rated-body{
  color: #4D4D56;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 23.8px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.rated-high{
  color: #18181B;
font-family: Inter;
font-size: 38.906px;
font-style: normal;
font-weight: 300;
line-height: 48px; /* 123.373% */
letter-spacing: -0.89px;
}
.tdk-offering{
  color: #18181B;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 23.8px; /* 170% */
letter-spacing: 1px;
text-transform: uppercase;
}
.offering-head{
  color: #18181B;
font-family: Inter;
font-size: 54.906px;
font-style: normal;
font-weight: 300;
line-height: 67.2px; /* 122.39% */
letter-spacing: -1.25px;
}
.offering-card{
  border-radius: 12px !important;
background: #FFF!important;
box-shadow: 0px 8px 32px 0px rgba(24, 24, 27, 0.06), 0px 0px 0px 1px rgba(24, 24, 27, 0.04)!important;
}
.offering-card-head{
  color: #18181B;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: 28.8px; /* 120% */
letter-spacing: -0.47px;
}
.offering-card-body{
  color: #4D4D56;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 23.8px; /* 170% */
letter-spacing: -0.09px;
}
.find-right-text{
  color: #18181B;
font-family: Inter;
font-size: 39.219px;
font-style: normal;
font-weight: 300;
line-height: 48px; /* 122.39% */
letter-spacing: -0.89px;
}
.find-body{
  color: #18181B;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 23.8px; /* 170% */
letter-spacing: -0.09px;
}
.card-light-stories{
  color: #6A6A77;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20.4px; /* 170% */
letter-spacing: 0.01px;
}
.card-head-stories{
  color: #18181B;
font-family: Inter;
font-size: 23.813px;
font-style: normal;
font-weight: 300;
line-height: 28.8px; /* 120.945% */
letter-spacing: -0.47px;
}
.card-head-body{
  color: #4D4D56;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 23.8px; /* 170% */
letter-spacing: -0.09px;
}
.success-story-text{
  padding: 5%;
  font-weight: 500;
  color: var(--black-70, #3D3D3D);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}
.power-up-head{
  color: #18181B;
text-align: center;
font-family: Inter;
font-size: 55.891px;
font-style: normal;
font-weight: 300;
line-height: 67.2px; /* 120.235% */
letter-spacing: -1.25px;
}
.cyber-home-text{
  color: var(--black-70, #3D3D3D);
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 160% */
}
.partner-head{
  color: var(--black-70, #3D3D3D);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 150% */
}
.partner-body{
  color: var(--black-60, #525252);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 125% */
}
.grey-bg{
  border-radius: 35.386px;
  background: #F7F7FD;
  padding: 4%;
}
.book-head{
  color: #1A1A1A;
font-size: 19.95px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 29.925px */
}
.book-label{
  color: #0F3551;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 130%; /* 20.8px */
}
.btn-load-more{
  border-radius: 99px;
  border: 1px solid #18181B;
  color: #18181B;

text-align: center;
background-color: #fff;
font-family: Inter;
font-size: 14px;
font-style: normal;
width: 15%;
font-weight: 500;
line-height: 23.8px; /* 170% */
letter-spacing: -0.09px;
}

/* Tablet Style (768px and below) */
@media (max-width: 768px) {
  .mployr-feedback{
    height: 1200px;
    padding: 8px 25px !important;
  }
  .p-5 {
    padding: 1rem !important;
  }
  .about-us-head{
    padding-left: 10%;
  }
  .story-border-image2{
    position: relative;
    top: 33px;
    left: -12px;
    width: 48px;
  }
  .about-us-subHead{
    width: auto;
  }
  .location-image-section{
    padding: 0;
  }
  .career-openess {
    padding: 10% 0% !important;
  }
  .carousel-item img{
    margin-right: 22px !important;
  }
  .slider-image {
    top: 10%;
    right: 20%; 
    width: 100px;
    opacity: 0.9;
  }
  .industry-slider{
    top: 5%!important;
  }
  .slider-image-bespoke{
    top: 4%!important;
    right: 5%!important;
  }
  .staff-aug-bg-image{
    bottom: 89%;
    left: -17%;
  }
  .offer-bg-image{
    bottom: 72%!important;
    left: -18%!important;
  }
  .measuring-success-overlay{
    bottom: 76%!important;
    right: -20%!important;
  }
}

/* Mobile Style (480px and below) */
@media (max-width: 480px) {
  .slider-image {
    top: 10%;
    right: 10%;
    width: 80px;
    opacity: 1;
  }

  .font2 {
    font-size: 1.75rem;
  }
}
.center-row-overlay{
  position: absolute;
  left: 10%;
  top: 40px;
  width: 50%;
  height: 50%;
  transform: rotate(-4.391deg);
  flex-shrink: 0;
}
a.nav-link {
  color: #18181B !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 23.8px !important;
  letter-spacing: -0.18px !important;
}
li.nav-item{
  margin-left: 20px;
}
.success-story-card{
  border-radius: 16px !important;
  border: 1px solid rgba(24, 24, 27, 0.06) !important;
}
.footer-logo-text{
    color: #4D4D56!important;
    font-family: Inter!important;
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 300!important;
    line-height: 23.8px!important;
    letter-spacing: -0.09px!important;
}
.beSpokeArrow{
  width: 96px!important;
  height: 40px!important;
  flex-shrink: 0!important;
}
.want-demo{
  color: #18181B;
  font-family: Inter;
  font-size: 54.031px;
  font-style: normal;
  font-weight: 400;
  line-height: 67.2px;
  letter-spacing: -1.25px;
}
button.btn.btn-dark, a.btn.btn-dark {
  background: #18181B;
}
.navbar-dark .navbar-toggler {
  border: 1px solid #18181b !important;
  padding: 2% 4% !important;
  color: #18181b;
}
.font-18 {
  font-size: 18px;
}
.career-openess {
  padding: 10%; padding-bottom: 0%;
}
.custom-padding {
  padding-left: 20%;
  padding-right: 20%;
}

@media (max-width: 991px) {
  .custom-padding {
      padding-left: 0;
      padding-right: 0;
  }
}