.center-row {
  position: relative;
  /* background-image: url('./images/Vector\ 6.png'); */
  background-size: cover;
  background-size: 300px 300px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 10px;
}

/* Animated Section Styles */
.animated-section {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

.animated-section img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.animated-section img.active {
  opacity: 1;
}

.animated-content {
  position: absolute;
  z-index: 2;
  color: white;
  text-align: center;
  font-family: 'Inter', sans-serif;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

h1 span {
  display: block;
  opacity: 0;
  animation: slideIn 1s ease-in-out forwards;
}

.row.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  /* Adjust spacing if necessary */
}


.carousel-btn {
  width: 50px;
  /* Circular button size */
  height: 50px;
  border-radius: 50%;
  /* Make buttons circular */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ddd;
  /* Light border */
  background-color: #f8f9fa;
  /* Light gray background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional shadow */
  transition: all 0.3s ease;
  /* Smooth hover effect */
  cursor: pointer;
}

.carousel-btn img {
  width: 20px;
  /* Arrow icon size */
  height: 20px;
}

.carousel-btn:hover {
  background-color: #e2e6ea;
  /* Background color on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
}

.carousel-btn-prev {
  margin-right: 15px;
  /* Space between buttons */
}

.carousel-btn-next {
  margin-left: 15px;
  /* Space between buttons */
}
.custom-card-img {
  height: 220px;
  width: 100%;
  object-fit: cover;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.join-us-container {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

.join-us-btn {
width: 133.53px; /* Set button width to 30% */
text-align: center;
}
.carousel-item {
  display: none; /* Hide all slides initially */
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}
.carousel-item.active {
  display: flex !important; /* Show active slide */
}
.image-partner {
  max-width: 150px; /* Adjust as needed */
  height: auto;
}