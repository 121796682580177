.toggle-container {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  top: -25px;
  left: -10%;
}

@media (max-width: 480px) {
  .toggle-container {
      top: -10px;
      left: -5%;
  }
}

/* Hide the default checkbox */
.toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 25px;
}

/* Slider before content */
.toggle-slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Checkbox checked state */
.toggle-checkbox:checked+.toggle-slider {
  background-color: #2196F3;
}

/* Move the slider */
.toggle-checkbox:checked+.toggle-slider:before {
  transform: translateX(25px);
}