.time-slot {
  display: inline-block;
  margin: 5px;
  padding: 10px 15px;
  border: 1px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  color: #007bff;
  font-weight: bold;
}
.time-slot.active {
  background-color: #007bff;
  color: white;
}